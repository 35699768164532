import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"font-weight":"700"}
}
const _hoisted_2 = {
  key: 1,
  class: "time"
}
const _hoisted_3 = {
  key: 2,
  style: {"display":"flex","justify-content":"flex-end","height":"19px","align-items":"center"}
}

import { Spin } from '@/shared/ui';
import dayjs from 'dayjs'
// @ts-ignore */
import { VMarkdownView } from 'vue3-markdown'


export default /*@__PURE__*/_defineComponent({
  __name: 'LLMMessage',
  props: {
    text: {},
    role: {},
    created_at: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.text)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.role, "llm_msg"])
      }, [
        (_ctx.role === 'assistant')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Aida"))
          : _createCommentVNode("", true),
        _createVNode(_unref(VMarkdownView), { content: _ctx.text }, null, 8, ["content"]),
        (_ctx.created_at)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(dayjs)(_ctx.created_at).format('h:mm A')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_unref(Spin), { size: '10px' })
            ]))
      ], 2))
    : _createCommentVNode("", true)
}
}

})